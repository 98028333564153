import payload_plugin_rHviMFj3vW from "/root/.cache/act/62b57869d02c5437/hostexecutor/node_modules/.pnpm/@pinia+nuxt@0.10.1_pinia@3.0.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_A8BmSag74b from "/root/.cache/act/62b57869d02c5437/hostexecutor/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.9_better-sqlite3@11.8.1_ioredis@5.5.0_rollup@3.29.5_typescript@5.8.2_vite@6.2.0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_VZQSyFel90 from "/root/.cache/act/62b57869d02c5437/hostexecutor/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.9_better-sqlite3@11.8.1_ioredis@5.5.0_rollup@3.29.5_typescript@5.8.2_vite@6.2.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_pKy54Sqt8U from "/root/.cache/act/62b57869d02c5437/hostexecutor/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.9_better-sqlite3@11.8.1_ioredis@5.5.0_rollup@3.29.5_typescript@5.8.2_vite@6.2.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_8PfiPRuxmr from "/root/.cache/act/62b57869d02c5437/hostexecutor/node_modules/.pnpm/@prisma+nuxt@0.3.0_typescript@5.8.2_vite@6.2.0/node_modules/@prisma/nuxt/dist/runtime/plugin.mjs";
import _0_siteConfig_NuUHudmnbT from "/root/.cache/act/62b57869d02c5437/hostexecutor/node_modules/.pnpm/nuxt-site-config@3.1.3_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_0L8f8iQjme from "/root/.cache/act/62b57869d02c5437/hostexecutor/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.9_better-sqlite3@11.8.1_ioredis@5.5.0_rollup@3.29.5_typescript@5.8.2_vite@6.2.0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_3zubClL1lA from "/root/.cache/act/62b57869d02c5437/hostexecutor/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.9_better-sqlite3@11.8.1_ioredis@5.5.0_rollup@3.29.5_typescript@5.8.2_vite@6.2.0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_p7GSuLqQEw from "/root/.cache/act/62b57869d02c5437/hostexecutor/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.9_better-sqlite3@11.8.1_ioredis@5.5.0_rollup@3.29.5_typescript@5.8.2_vite@6.2.0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_7jDg428pNc from "/root/.cache/act/62b57869d02c5437/hostexecutor/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.9_better-sqlite3@11.8.1_ioredis@5.5.0_rollup@3.29.5_typescript@5.8.2_vite@6.2.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ScBjjXOyot from "/root/.cache/act/62b57869d02c5437/hostexecutor/node_modules/.pnpm/@pinia+nuxt@0.10.1_pinia@3.0.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/root/.cache/act/62b57869d02c5437/hostexecutor/.nuxt/components.plugin.mjs";
import prefetch_client_ussMrHtGNG from "/root/.cache/act/62b57869d02c5437/hostexecutor/node_modules/.pnpm/nuxt@3.15.4_@types+node@22.13.9_better-sqlite3@11.8.1_ioredis@5.5.0_rollup@3.29.5_typescript@5.8.2_vite@6.2.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_eNelkHx7DD from "/root/.cache/act/62b57869d02c5437/hostexecutor/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_Bf5OEGYPbA from "/root/.cache/act/62b57869d02c5437/hostexecutor/node_modules/.pnpm/@nuxt+icon@1.10.3_vite@6.2.0_vue@3.5.13/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import primevue_plugin_egKpok8Auk from "/root/.cache/act/62b57869d02c5437/hostexecutor/.nuxt/primevue-plugin.mjs";
import plugin_client_Pjim3fbt6e from "/root/.cache/act/62b57869d02c5437/hostexecutor/node_modules/.pnpm/@primevue+nuxt-module@4.3.1_rollup@3.29.5_vue@3.5.13/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import fetch_4Vfx5IessU from "/root/.cache/act/62b57869d02c5437/hostexecutor/app/plugins/fetch.ts";
export default [
  payload_plugin_rHviMFj3vW,
  revive_payload_client_A8BmSag74b,
  unhead_VZQSyFel90,
  router_pKy54Sqt8U,
  plugin_8PfiPRuxmr,
  _0_siteConfig_NuUHudmnbT,
  payload_client_0L8f8iQjme,
  navigation_repaint_client_3zubClL1lA,
  check_outdated_build_client_p7GSuLqQEw,
  chunk_reload_client_7jDg428pNc,
  plugin_vue3_ScBjjXOyot,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ussMrHtGNG,
  plugin_client_eNelkHx7DD,
  plugin_Bf5OEGYPbA,
  primevue_plugin_egKpok8Auk,
  plugin_client_Pjim3fbt6e,
  fetch_4Vfx5IessU
]